import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Masonry from "react-masonry-css"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import PortfolioItem from "./../components/PortfolioItem"
import JournalItem from "./../components/JournalItem"

const Wrapper = styled.div`
  width: 100%;
  max-width: ${props => props.theme.space.maxContentWidthMobile};
  margin: 0 auto;

  .items-grid {
    display: flex;
    margin-left: -${props => props.theme.mobileVW(30)};
    width: auto;
  }

  .items-grid_column {
    padding-left: ${props => props.theme.mobileVW(30)};
    background-clip: padding-box;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: ${props => props.theme.space.maxContentWidthDesktop};

    .items-grid {
      margin-left: -${props => props.theme.desktopVW(50)};
    }

    .items-grid_column {
      padding-left: ${props => props.theme.desktopVW(50)};
    }
  }
`

const Categories = styled.ul`
  padding: ${props => props.theme.mobileVW(10)} 0;

  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  margin-bottom: ${props => props.theme.mobileVW(40)};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin-bottom: ${props => props.theme.desktopVW(100)};

    padding: ${props => props.theme.desktopVW(40)} 0;
  }
`

const CategoryItem = styled.li`
  display: block;

  margin: 0;
  margin-bottom: ${props => props.theme.mobileVW(10)};

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    display: inline-block;

    &:first-of-type {
      margin-left: 0;
    }

    margin-bottom: 0;
    margin-right: ${props => props.theme.desktopVW(50)};
  }
`

const CategoryButton = styled.button`
    font-family: ${props => props.theme.fontFamily.brandonRegular};
    font-size: ${props => props.theme.fontSize.m6};

    color: ${props => props.theme.colors.black};

    opacity: 0.25;

    cursor: pointer;

    ${({ active }) =>
      active &&
      `
        opacity: 1;
    `}

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
      font-size: ${props => props.theme.fontSize.d6};
    }
`

const breakpointsConfig = {
  default: 3,
  1200: 2,
  1000: 1,
}

const ItemsGrid = ({ data, type }) => {
  const [currentCategory, setCurrentCategory] = useState("")
  const [gridItems, setGridItems] = useState(data)
  const [categories, setCategories] = useState([])

  useEffect(() => {
    const allItems = data.filter(item => {
      if (JSON.stringify(item.categories).includes(currentCategory)) {
        return item
      }
    })

    function removeDuplicates(myArr, prop) {
      return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
      })
    }

    function getAllCategories(items) {
      const allCategories = []
      data.map(item => {
        item.categories.map(category => allCategories.push(category))
      })
      return allCategories
    }

    const uniqueCategories = removeDuplicates(getAllCategories(data), "name")

    setCategories(uniqueCategories)
    setGridItems(allItems)
  }, [currentCategory])

  return (
    <>
      <ScrollAnimation animateIn="fadeInUp" duration={1.5} animateOnce={true}>
        <Categories>
          <Wrapper>
            <CategoryItem>
              <CategoryButton
                active={currentCategory == ""}
                onClick={() => setCurrentCategory("")}
              >
                Alles
              </CategoryButton>
            </CategoryItem>
            {categories.map((item, index) => (
              <CategoryItem key={index}>
                <CategoryButton
                  active={currentCategory == item.slug}
                  onClick={() => setCurrentCategory(item.slug)}
                >
                  {item.name}
                </CategoryButton>
              </CategoryItem>
            ))}
          </Wrapper>
        </Categories>
      </ScrollAnimation>
      <Wrapper>
        <Masonry
          breakpointCols={breakpointsConfig}
          className="items-grid"
          columnClassName="items-grid_column"
        >
          {gridItems.map((item, index) => (
            <ScrollAnimation
              animateIn="fadeInUp"
              duration={1}
              animateOnce={true}
              key={index}
            >
              {type == "portfolio" ? (
                <PortfolioItem data={item} />
              ) : (
                <JournalItem data={item} />
              )}
            </ScrollAnimation>
          ))}
        </Masonry>
      </Wrapper>
    </>
  )
}

export default ItemsGrid
