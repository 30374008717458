import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Text from "./Text"
import ButtonInternal from "./Buttons/ButtonInternal"

const StyledServiceItem = styled.li`
  display: inline-block;

  .button-internal {
    opacity: 0.5;
  }
`

const Illustration = styled.div`
  width: 100%;
  max-width: ${props => props.theme.mobileVW(175)};
  margin: 0 auto ${props => props.theme.mobileVW(20)} auto;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    width: ${props => props.theme.desktopVW(175)};
    height: ${props => props.theme.desktopVW(250)};
    margin: 0 auto ${props => props.theme.desktopVW(50)} auto;
  }
`

const Title = styled.h5`
  margin-bottom: ${props => props.theme.desktopVW(30)};

  font-family: ${props => props.theme.fontFamily.circularBook};
  font-size: ${props => props.theme.mobileVW(18)};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    font-size: ${props => props.theme.fontSize.d6};
  }
`

const TextWrapper = styled.div`
  margin-bottom: ${props => props.theme.desktopVW(45)};
`

const Image = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const ServiceItem = ({ data: { name, slug, excerpt, illustration } }) => {
  return (
    <StyledServiceItem>
      <Illustration>
        <Image fluid={illustration.fluid} />
      </Illustration>
      <Title>{name}</Title>
      <TextWrapper>
        <Text data={excerpt} />
      </TextWrapper>
      <ButtonInternal label="Lees verder" to={`/services/${slug}`} />
    </StyledServiceItem>
  )
}

export default ServiceItem
