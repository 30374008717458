import React from 'react'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'

import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.min.css'

const Styles = css`
    flex-grow: 1;

    width: 100%;
    margin: 0 auto;
`

const IllustrationWrapper = styled.div`
    ${Styles}

    max-width: ${props => props.theme.mobileVW(250)};
    padding-bottom: calc(${props => props.theme.space.paddingMobile} * 2);

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        max-width: ${props => props.theme.desktopVW(450)};
        
        padding-bottom: 0;
    }
`

const ImageWrapper = styled.div`
    ${Styles}

    overflow: hidden;
`

const ContentImage = ({
    data: {
        image,
        type
    },
    delay
}) => {
    return (
        <>
            {image.map((image, index) => {
                if (type == 'Illustration') {
                    return (
                        <IllustrationWrapper className='content-image illustration' key={index}>
                            <ScrollAnimation animateIn='fadeInUp' delay={delay + (index * 50)} duration={1.5}>
                                <Img fluid={image.fluid} />
                            </ScrollAnimation>
                        </IllustrationWrapper>
                    )
                } else {
                    return (
                        <ImageWrapper className='content-image image' key={index}>
                            <ScrollAnimation animateIn='fadeInUp' delay={delay + (index * 50)} duration={1.5}>
                                <Img fluid={image.fluid} />
                            </ScrollAnimation>
                        </ImageWrapper>
                    )
                }
            })}
        </>
    )
}

export default ContentImage