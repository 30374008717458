import React from 'react'
import styled from 'styled-components'

import Text from './../components/Text'
import ItemsGrid from './../components/ItemsGrid'

const StyledJournal = styled.div`
    padding: ${props => props.theme.space.paddingMobile};

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        padding: 0;
    }
`

const Wrapper = styled.div`
    width: 100%;
    max-width: ${props => props.theme.space.maxContentWidthMobile};
    margin: 0 auto;

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        max-width: ${props => props.theme.space.maxContentWidthDesktop};
    }
`

const TextWrapper = styled.div`
    max-width: ${props => props.theme.mobileVW(500)};
    
    margin-bottom: ${props => props.theme.mobileVW(15)};

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        max-width: ${props => props.theme.desktopVW(500)};

        margin-bottom: ${props => props.theme.desktopVW(60)};
    }
`

const Journal = ({
    data: {
        title,
        items
    }
}) => {
    return (
        <StyledJournal>
            <Wrapper>
                <TextWrapper>
                    <Text data={title} />
                </TextWrapper>
            </Wrapper>
            <ItemsGrid data={items} type='journal' />
        </StyledJournal>
    )
}

export default Journal