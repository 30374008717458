import React from "react"
import styled from "styled-components"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import Text from "./Text"

const Wrapper = styled.div`
  width: 100%;

  ul,
  ol {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 1rem; /* Adjust as needed for alignment */
    list-style-position: outside;
  }

  ul {
    list-style-type: disc; /* Default style for unordered lists */
  }

  ol {
    list-style-type: decimal; /* Default style for ordered lists */
  }

  ul li,
  ol li {
    padding-left: 0.25rem;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: ${props => props.theme.desktopVW(700)};
  }
`

const ContentText = ({ data: { text }, color }) => {
  return (
    <Wrapper className="content-text">
      <ScrollAnimation animateIn="fadeInUp" duration={1.5}>
        <Text color={color && color.color} data={text} />
      </ScrollAnimation>
    </Wrapper>
  )
}

export default ContentText
