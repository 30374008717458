import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.min.css'

const Wrapper = styled.div`
    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        display: flex;
    }
`

const ImageWrapper = styled.div`
    flex-grow: 1;

    &:not(:last-of-type) {
        margin-bottom: ${props => props.theme.space.paddingMobile};
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        &:nth-of-type(2) {
            margin-left: ${props => props.theme.desktopVW(50)};
        }
    }
`

const ContentImages = ({
    data: {
        image
    }
}) => {
    return (
        <Wrapper>
            {image.map((image, index) => (
                <ImageWrapper className='content-images' key={index}>
                    <ScrollAnimation animateIn='fadeInUp' delay={index * 150}>
                        <Img fluid={image.fluid} />
                    </ScrollAnimation>
                </ImageWrapper>
            ))}
        </Wrapper>
    )
}

export default ContentImages