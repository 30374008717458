import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import { useSpring, animated } from "react-spring"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"
import theme from "./../styles/theme"

import Text from "./Text"

const StyledJournalItem = styled(animated.article)`
  text-align: left;

  margin-bottom: ${props => props.theme.mobileVW(30)};

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin-bottom: ${props => props.theme.desktopVW(50)};

    &:last-of-type {
      margin-bottom: ${props => props.theme.desktopVW(50)};
    }
  }
`

const ImageWrapper = styled.div`
  img {
    transition: all 1s linear !important;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`

const Image = styled(Img)`
  margin-bottom: ${props => props.theme.mobileVW(20)};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin-bottom: ${props => props.theme.desktopVW(35)};
  }
`

const Title = styled.h5`
  display: block;

  margin-bottom: ${props => props.theme.desktopVW(30)};

  font-family: ${props => props.theme.fontFamily.circularBook};
  font-size: ${props => props.theme.mobileVW(18)};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    font-size: ${props => props.theme.fontSize.d6};
  }
`

const TextWrapper = styled.div`
  width: 100%;

  opacity: 0.5;
`

const JournalItem = ({ data: { name, slug, thumbnail, excerpt } }) => {
  const [hover, setHover] = useState(false)
  const [menuColor, setMenuColor] = useState("#000")

  useEffect(() => {
    setMenuColor(
      getComputedStyle(document.body).getPropertyValue("--menu-color")
    )
  }, [])

  const props = useSpring({
    opacity: hover ? 1 : 0,
  })

  return (
    <StyledJournalItem
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <AniLink to={`/journals/${slug}`} cover direction="right" bg={menuColor}>
        <ImageWrapper>
          <Image fluid={thumbnail.fluid} />
        </ImageWrapper>
        <Title>{name}</Title>
        <TextWrapper>
          <Text data={excerpt}></Text>
        </TextWrapper>
      </AniLink>
    </StyledJournalItem>
  )
}

export default JournalItem
