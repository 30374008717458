import React, { useRef, useState, useCallback, useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image"

// import AliceCarousel from 'react-alice-carousel'
// import 'react-alice-carousel/lib/alice-carousel.css'

import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

import theme from "./../styles/theme"
import Text from "./Text"
import ArrowSvg from "./Icons/Arrow"

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const CarouselWrapper = styled.div`
  position: relative;

  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: ${props => props.theme.desktopVW(600)};
  }
`

const ImageWrapper = styled.div`
    position: relative;

    /* padding: 0 ${props => props.theme.mobileVW(30)}; */

    width: 100%;

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        padding: 0;
    }
`

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: ${props => props.theme.desktopVW(500)};
  }
`

const Header = styled.div`
  padding: ${props => props.theme.mobileVW(90)}
    ${props => props.theme.mobileVW(30)};

  text-align: center;

  h2 {
    margin-bottom: 0;
  }

  &.desktop {
    display: none;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    h2 {
      font-size: ${props => props.theme.desktopVW(50)};
    }

    padding: 0;

    text-align: left;

    &.mobile {
      display: none;
    }

    &.desktop {
      display: block;
    }
  }
`

const Copy = styled.div`
  position: relative;
`

const StyledImage = styled(Img)`
  img {
    opacity: 1 !important;
  }
`

const Content = styled.div`
  display: block;

  background-color: ${props => props.backgroundColor};

  padding: ${props => props.theme.mobileVW(60)}
    ${props => props.theme.mobileVW(30)} ${props => props.theme.mobileVW(30)}
    ${props => props.theme.mobileVW(30)};

  text-align: center;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin-bottom: ${props => props.theme.desktopVW(30)};
    padding: 0;

    text-align: left;

    p {
      margin: ${props => props.theme.desktopVW(20)} 0
        ${props => props.theme.desktopVW(40)} 0;
    }
  }
`

const Description = styled(Text)`
  margin-bottom: ${props => props.theme.mobileVW(30)};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin-bottom: ${props => props.theme.desktopVW(50)};
  }
`

const Name = styled.h5`
  margin-bottom: ${props => props.theme.mobileVW(5)};
  padding: 0 ${props => props.theme.mobileVW(50)};

  font-family: ${props => props.theme.fontFamily.portraitMedium};
  font-size: ${props => props.theme.fontSize.m6};
  line-height: 1;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin-bottom: 0;
    padding: 0;

    font-size: ${props => props.theme.fontSize.d6};
  }
`

const Subtitle = styled.h6`
  font-family: ${props => props.theme.fontFamily.portraitLightItalic};
  font-size: ${props => props.theme.fontSize.m6};
  font-style: italic;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    font-size: ${props => props.theme.fontSize.d6};
  }
`

const Current = styled.p`
  display: none;

  margin-bottom: ${props => props.theme.mobileVW(30)};

  font-size: ${props => props.theme.fontSize.m8};

  opacity: 0.25;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    display: block;

    margin-bottom: ${props => props.theme.desktopVW(30)};

    font-size: ${props => props.theme.fontSize.d8};
  }
`

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-bottom: ${props => props.theme.mobileVW(40)};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    display: block;

    margin-top: ${props => props.theme.desktopVW(40)};
    margin-bottom: 0;
  }
`

const ButtonNavigation = styled.button`
  position: relative;
  width: ${props => props.theme.mobileVW(35)};
  height: ${props => props.theme.mobileVW(35)};

  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.25);

  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);

  cursor: pointer;

  .arrow {
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    width: ${props => props.theme.mobileVW(10)};
  }

  &:first-of-type {
    margin-right: ${props => props.theme.mobileVW(5)};

    .arrow {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  &:hover {
    border: 1px solid rgba(0, 0, 0, 1);
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    width: ${props => props.theme.desktopVW(50)};
    height: ${props => props.theme.desktopVW(50)};

    .arrow {
      width: ${props => props.theme.desktopVW(15)};
    }

    &:first-of-type {
      margin-right: ${props => props.theme.desktopVW(15)};
    }
  }
`

const Testimonials = ({ className, background, data: { title, items } }) => {
  const [swiper1, updateSwiper1] = useState(null)
  const [swiper2, updateSwiper2] = useState(null)
  const [currentIndex, updateCurrentIndex] = useState(0)

  const params1 = {
    loop: true,
    preloadImages: false,
    slidesPerView: 1,
    allowTouchMove: false,
    effect: "fade",
    getSwiper: updateSwiper1,
  }

  const params2 = {
    loop: true,
    preloadImages: false,
    allowTouchMove: false,
    slidesPerView: 1,
    autoHeight: true,
    effect: "fade",
    getSwiper: updateSwiper2,
  }

  const goNext = () => {
    if (swiper1 !== null && swiper2 !== null) {
      swiper1.slideNext()
      swiper2.slideNext()
    }
  }

  const goPrev = () => {
    if (swiper1 !== null && swiper2 !== null) {
      swiper1.slidePrev()
      swiper2.slidePrev()
    }
  }

  const updateIndex = useCallback(() => {
    updateCurrentIndex(swiper1.realIndex)
    updateCurrentIndex(swiper2.realIndex)
  }, [swiper1, swiper2])

  useEffect(() => {
    if (swiper1 !== null && swiper2 !== null) {
      swiper1.on("slideChange", updateIndex)
      swiper2.on("slideChange", updateIndex)
    }

    return () => {
      if (swiper1 !== null && swiper2 !== null) {
        swiper1.off("slideChange", updateIndex)
        swiper2.off("slideChange", updateIndex)
      }
    }
  }, [swiper1, swiper2, updateIndex])

  return (
    <Wrapper className={className}>
      <Header className="mobile">
        <Current>
          {(currentIndex + 1).toString().padStart(2, "0")} /{" "}
          {items.length.toString().padStart(2, "0")}
        </Current>
        <Text data={title} />
      </Header>

      <CarouselWrapper>
        <Swiper {...params1}>
          {items.map((item, index) => {
            return (
              <ImageWrapper key={index}>
                <StyledImage fluid={item.image.fluid} alt={item.image.alt} />
              </ImageWrapper>
            )
          })}
        </Swiper>
      </CarouselWrapper>

      <CopyWrapper>
        <Header className="desktop">
          <Current>
            {(currentIndex + 1).toString().padStart(2, "0")} /{" "}
            {items.length.toString().padStart(2, "0")}
          </Current>
          <Text data={title} />
        </Header>
        <Copy>
          <Swiper {...params2}>
            {items.map((item, index) => (
              <Content key={index} backgroundColor={background}>
                <Description data={item.text} />
                <Name>{item.name}</Name>
                <Subtitle>{item.subtitle}</Subtitle>
              </Content>
            ))}
          </Swiper>
        </Copy>
        <Navigation>
          <ButtonNavigation onClick={() => goPrev()}>
            <ArrowSvg />
          </ButtonNavigation>
          <ButtonNavigation onClick={() => goNext()}>
            <ArrowSvg />
          </ButtonNavigation>
        </Navigation>
      </CopyWrapper>
    </Wrapper>
  )
}

// class Testimonials extends React.Component {

//     state = {
//         currentIndex: 0,
//         imageCarousel: this.imageCarousel(),
//         textCarousel: this.textCarousel(),
//         deviceWidth: 0
//     }

//     slideTo = (i) => {
//         this.setState({
//             currentIndex: i
//         })
//     }

//     onSlideChanged = (e) => {
//         this.setState({
//             currentIndex: e.item
//         })
//     }

//     slideNext = () => {
//         this.setState({
//             currentIndex: this.state.currentIndex + 1
//         })
//     }

//     slidePrev = () => {
//         this.setState({
//             currentIndex: this.state.currentIndex - 1
//         })
//     }

//     imageCarousel() {
//         return this.props.data.items.map((item, index) => (
//             <StyledImage key={index} fluid={item.image.fluid} />
//         ))
//     }

//     textCarousel() {
//         return this.props.data.items.map((item, index) => (
//             <Content key={index}>
//                 <Description data={item.text} />
//                 <Name>{item.name}</Name>
//                 <Subtitle>{item.subtitle}</Subtitle>
//             </Content>
//         ))
//     }

//     checkDeviceWidth() {
//         if (typeof window !== 'undefined') this.setState({ deviceWidth: window.innerWidth })
//     }

//     componentDidMount() {
//         this.checkDeviceWidth()
//     }

//     render() {

//         const { imageCarousel, textCarousel, currentIndex } = this.state
//         const { data: { title, items } } = this.props
//         const rightIndex = currentIndex + 1;

//         return (
//             <Wrapper>
//                 <ImageWrapper>
//                     <AliceCarousel
//                         dotsDisabled={true}
//                         buttonsDisabled={true}
//                         slideToIndex={currentIndex}
//                         items={imageCarousel}
//                         duration={250}
//                         swipeDisabled={this.state.deviceWidth > parseInt(theme.breakpoints.desktop) ? true : false}
//                         onSlideChanged={this.onSlideChanged}
//                         fadeOutAnimation={true}
//                     />
//                     <Navigation>
//                         <ButtonNavigation onClick={() => this.slidePrev()}><ArrowSvg /></ButtonNavigation>
//                         <ButtonNavigation onClick={() => this.slideNext()}><ArrowSvg /></ButtonNavigation>
//                     </Navigation>
//                 </ImageWrapper>
//                 <TextWrapper>
// <Current>{rightIndex.toString().padStart(2, '0')} / {items.length.toString().padStart(2, '0')}</Current>
// <Text data={title} />
//                     <AliceCarousel
//                         dotsDisabled={true}
//                         buttonsDisabled={true}
//                         slideToIndex={currentIndex}
//                         items={textCarousel}
//                         duration={250}
//                         swipeDisabled={this.state.deviceWidth > parseInt(theme.breakpoints.desktop) ? true : false}
//                         onSlideChanged={this.onSlideChanged}
//                         // autoPlay={true}
//                         // autoPlayInterval={7000}
//                         fadeOutAnimation={true}
//                     />
//                 </TextWrapper>
//             </Wrapper>
//         )
//     }
// }

export default Testimonials
