import React from 'react'

import Row from './../Row'
import Banner from './../Banner'

const SectionProvider = ({ sections }) => (
    <>
        {sections.map((item, index) => (
            (() => {
                switch (item.__typename) {
                    case 'ContentfulSectionHeroBanner': return <Banner data={item} key={index} type='hero' />
                    case 'ContentfulSectionBanner': return <Banner data={item} key={index} type='normal' />
                    case 'ContentfulSectionRow': return <Row data={item} key={index} />
                }
            })()
        ))}
    </>
)

export default SectionProvider