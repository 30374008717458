import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import theme from "./../styles/theme"

import Text from "./Text"
import Video from "./Video"
import ButtonInternal from "./Buttons/ButtonInternal"
import YoutubeVideo from "./YoutubeVideo"

const StyledBanner = styled.section`
    position: relative;

    width: 100vw;
    
    ${({ type }) =>
      type == "hero"
        ? `
        height: 100vh;
    `
        : `
        height: 60vh;
        max-height: 800px;
    `}

    background-color: ${({ background }) => background};
`

const BannerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: ${props => props.theme.space.maxContentWidthDesktop};
    margin: 0 auto;

    ${({ alignment }) =>
      alignment === "Left"
        ? `            
            align-items: flex-start;
        `
        : alignment === "Center"
        ? `
            align-items: center;
        `
        : `
            align-items: flex-end;
        `}
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;

  text-align: center;

  width: 100%;
  max-width: ${props => props.theme.space.maxContentWidthMobile};

  margin: 0 auto;
  padding: 0 ${props => props.theme.space.paddingMobile};

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    font-family: ${props => props.theme.fontFamily.circularBook};
    font-weight: 400;
  }

  p {
    a {
      display: block;

      opacity: 1;

      &:not(:last-of-type) {
        margin-bottom: calc(${props => props.theme.space.paddingMobile} / 4);
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: ${props => props.theme.desktopVW(850)};

    ${({ alignment }) =>
      alignment == "Left"
        ? `
            justify-content: flex-start;
            margin: 0;
            text-align: left;
        `
        : alignment == "Center"
        ? `
            justify-content: center;
            text-align: center;
        `
        : `
            justify-content: flex-end;
            margin: 0;
            text-align: left;
        `}

    padding: 0;

    h1,
    h2 {
      font-size: ${props => props.theme.fontSize.d2};
    }

    p {
      font-size: ${props => props.theme.fontSize.d7};

      a {
        &:not(:last-of-type) {
          margin-bottom: calc(${props => props.theme.space.paddingDesktop} / 4);
        }
      }
    }
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  ${props =>
    props.overlayAmount &&
    `
        &:after {
            content: '';
            position: absolute;
            
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            background-color: ${
              props.color == "#fff"
                ? `rgba(0, 0, 0, ${props.overlayAmount / 10})`
                : `rgba(255, 255, 255, ${props.overlayAmount / 10})`
            };
        }
    `}
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  width: 100%;

  position: relative;

  padding: 0 ${props => props.theme.space.paddingMobile};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    position: absolute;

    right: ${props => props.theme.desktopVW(150)};
    bottom: ${props => props.theme.desktopVW(150)};

    padding: 0;
  }
`

const Image = styled(Img)`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`

const Banner = ({ type, data }) => {
  const {
    title,
    titleColor,
    background,
    titleAlignment,
    button,
    overlayAmount,
  } = data

  let backgroundComponent

  if (background[0].youtubeId) {
    backgroundComponent = (
      <BackgroundWrapper
        color={titleColor && titleColor.color}
        overlayAmount={overlayAmount}
      >
        <YoutubeVideo data={background[0]} />
      </BackgroundWrapper>
    )
  } else if (background[0].image) {
    backgroundComponent = (
      <BackgroundWrapper
        color={titleColor && titleColor.color}
        overlayAmount={overlayAmount}
      >
        <Image fluid={background[0].image.fluid} objectFit="cover" />
      </BackgroundWrapper>
    )
  }

  const Banner = (
    <StyledBanner
      type={type}
      background={background[0].color && background[0].color.color}
    >
      {background != null && backgroundComponent}
      <BannerInner alignment={titleAlignment && titleAlignment}>
        <TextWrapper alignment={titleAlignment && titleAlignment}>
          <Text data={title} color={titleColor && titleColor.color} />
        </TextWrapper>
        {button && (
          <ButtonWrapper>
            <ButtonInternal
              label={button.text}
              to={button.link}
              color={button.color.color}
            />
          </ButtonWrapper>
        )}
      </BannerInner>
    </StyledBanner>
  )

  return (
    <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.5}>
      {Banner}
    </ScrollAnimation>
  )
}

export default Banner
