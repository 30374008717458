import React from 'react'
import styled from 'styled-components'

import Services from './../components/Services'
import Portfolio from './../components/Portfolio'
import Content from './../components/Content'
import Testimonials from './../components/Testimonials'
import Journal from './../components/Journal'

const StyledRow = styled.section`
    position: relative;

    &:first-of-type {
        &:not(.row-contentblocks) {
            padding-top: ${props => props.theme.space.paddingMobile};
        }
    }

    background-color: ${({ background }) => background};

    color: ${({ color }) => color};

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        &:first-of-type {
            &:not(.row-contentblocks) {
                padding-top: ${props => props.theme.space.paddingDesktop};
            }
        }

        div {
            &:first-of-type {
                padding-top: 0;
            }

            &:last-of-type {
                padding-bottom: 0;
            }
        }

        padding: ${props => props.theme.space.paddingDesktop} 0;
    }
` 

const Inner = styled.div`
    width: 100%;
    max-width: ${props => props.theme.space.maxContentWidthMobile};
    margin: 0 auto;

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        max-width: ${props => props.theme.space.maxContentWidthDesktop};
    }
`

const Row = ({
    data
}) => {
    
    const {
        backgroundColor,
        textColor,
        content,
        name
    } = data

    return (
        <StyledRow 
            id={name.toLowerCase().replace(/[^\w\s]/gi, '').replace(/ /g, '-')} 
            className={`row-${data.content[0].__typename.replace('ContentfulSection', '').toLowerCase()}`}
            background={backgroundColor ? backgroundColor.color : '#fff'} 
            color={textColor ? textColor.color : '#000'}
        >
            {content && content.map((item, index) => (
                (() => {
                    switch (item.__typename) {
                        case 'ContentfulSectionServices': return <Inner key={index}><Services data={item} /></Inner>
                        case 'ContentfulSectionPortfolio': return <Portfolio key={index} data={item} />
                        case 'ContentfulSectionJournal': return <Journal key={index} data={item} />
                        case 'ContentfulSectionTestimonials': return <Inner key={index}><Testimonials background={backgroundColor.color} data={item} /></Inner>
                        case 'ContentfulSectionContentBlockS': return <Content data={item} key={index} color={textColor} />
                    }
                })()
            ))}
        </StyledRow>
    )
}

export default Row