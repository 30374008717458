import React from 'react'
import styled, { css } from 'styled-components'

import ContentText from './ContentText'
import ContentImage from './ContentImage'
import ContentVideo from './ContentVideo'

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    width: 100%;

    margin-bottom: 0;

    font-size: 0;

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        grid-template-columns: 1fr 1fr;
        grid-gap: ${props => props.theme.desktopVW(100)};
    }
`

const TextWrapper = styled.div`
    padding: calc(${props => props.theme.space.paddingMobile} * 2) ${props => props.theme.space.paddingMobile};

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        padding: 0;
    }
`

const ImageWrapper = styled.div`
    
`

const ContentImageText = ({
    data: {
        content
    },
    color
}) => {
    return (
        <Wrapper>
            {content.map((item, index) => (
                (() => {
                    switch (item.__typename) {
                        case 'ContentfulComponentText': return <TextWrapper key={index}><ContentText data={item} color={color} /></TextWrapper>
                        case 'ContentfulComponentImage': return <ImageWrapper key={index}><ContentImage data={item} delay={100} /></ImageWrapper>
                        case 'ContentfulComponentVideo': return <ImageWrapper key={index}><ContentVideo data={item} delay={100} /></ImageWrapper>
                    }
                })()
            ))}
        </Wrapper>
    )
}

export default ContentImageText