import React from "react"
import styled from "styled-components"

import ContentText from "./ContentText"
import ContentImage from "./ContentImage"
import ContentImages from "./ContentImages"
import ContentImageText from "./ContentImageText"

const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    
    width: 100%;
    max-width: ${props => props.theme.space.maxContentWidthMobile};

    margin: 0 auto ${props => props.theme.space.paddingMobile} auto;
    padding: calc(${props => props.theme.space.paddingMobile} * 2) ${props =>
  props.theme.space.paddingMobile};

    ${({ alignment }) =>
      alignment == "Left"
        ? `
        justify-content: flex-start;
    `
        : alignment == "Center"
        ? `
        justify-content: center;
    `
        : alignment == "Right"
        ? `
        justify-content: flex-end;
    `
        : `
        justify-content: flex-start;
    `}

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        max-width: ${props => props.theme.space.maxContentWidthDesktop};

        margin: 0 auto;
        padding: ${props => props.theme.space.paddingDesktop} 0;
    }
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: ${props => props.theme.space.maxContentWidthMobile};

  margin: 0 auto;
  padding: ${props => props.theme.space.paddingMobile};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: ${props => props.theme.space.maxContentWidthDesktop};
    padding: calc(${props => props.theme.space.paddingDesktop} / 2) 0;
  }
`

const ImageTextWrapper = styled.div`
  width: 100%;
  max-width: ${props => props.theme.space.maxContentWidthMobile};
  margin: 0 auto;

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    max-width: ${props => props.theme.space.maxContentWidthDesktop};
  }
`

const Content = ({ data: { blocks }, color }) => {
  return (
    <>
      {blocks.map((item, index) =>
        (() => {
          switch (item.__typename) {
            case "ContentfulComponentText":
              return (
                <TextWrapper
                  className="text-wrapper"
                  key={index}
                  alignment={item.alignment}
                >
                  <ContentText data={item} color={color} />
                </TextWrapper>
              )
            case "ContentfulComponentImages":
              return (
                <ImageWrapper key={index}>
                  <ContentImages data={item} />
                </ImageWrapper>
              )
            case "ContentfulComponentImage":
              return (
                <ImageWrapper key={index}>
                  <ContentImage data={item} />
                </ImageWrapper>
              )
            case "ContentfulComponentImageText":
              return (
                <ImageTextWrapper key={index}>
                  <ContentImageText data={item} color={color} />
                </ImageTextWrapper>
              )
          }
        })()
      )}
    </>
  )
}

export default Content
