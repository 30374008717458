import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const StyledInlineVideo = styled.div`
	position: relative;
	
	width: 100%;
	height: 100%;

	overflow-y: hidden;
`

const Video = styled.video`
	width: 100%;
	height: 100%;

	object-fit: cover;
`
const StyledImage = styled(Img)`
	position: absolute;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
`

class InlineVideo extends React.Component {
    
    state = { ready: false, muted: true }
    video = React.createRef()

    switchMuted = () => {
        this.video.current.muted = !this.state.muted
        this.setState({ muted: !this.state.muted })
    }

    render = () => {

        const { webm, mp4, poster: { fluid } } = this.props.data
        const { ready, muted } = this.state

        return (
            <StyledInlineVideo>
                <Video
                    ref={this.video}
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    playsInline={true}
                    onCanPlay={() => this.setState({ ready: true })}
                >
                    {/* <source src={webm.file.url} type='video/webm' /> */}
                    <source src={mp4.file.url} type='video/mp4' />
                </Video>
				<StyledImage
                    fluid={fluid}
                    style={{
                        display: ready ? 'none' : 'block'
                    }}
                />
            </StyledInlineVideo>
        )
    }
}

export default InlineVideo