import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import YouTube from 'react-youtube'

const Wrapper = styled.div`
    position: relative;
    
    width: 100%;
    height: 100%;
`

const VideoForeground = styled.div`
    position: absolute;
    
    top: 0;
    left: 0;
    
    width: 100%;
    height: 100%;
    
    pointer-events: none;

    @media (min-aspect-ratio: 16/9) {
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        width: 100%;
        height: 150%;
    }

    @media (max-aspect-ratio: 16/9) {
        left: -100%;

        width: 300%; 
        height: 110%;
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        top: 50%;
        left: 50%;
        
        transform: translate(-50%, -50%);
        
        width: 170%;
        height: 170%;
    }
`

const VideoBackground = styled.div`
    position: absolute;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    
    z-index: 0;
    overflow: hidden;
    
    background: #000;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
`

const Poster = styled(Img)`
    position: absolute !important;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;

    transition: opacity 1s cubic-bezier(.82,-.02,.45,1.22);

    opacity: ${props => props.videoPlaying == 'true' ? 0 : 1};
`

const YoutubeVideo = ({
    className, 
    data, 
    type
}) => {

    const [player, setPlayer] = useState(false)
    const [timer, setTimer] = useState(null)
    const [videoLoaded, setVideoLoaded] = useState(false)
    const [videoPlaying, setVideoPlaying] = useState(false)

    const videoOptions = {
        playerVars: {
            autoplay: 1,
            controls: 0,
            rel: 0,
            loop: 1,
            playlist: 0,
            showinfo: 0,
            playsinline: 1,
            enablejsapi: 1,
            host: 'https://www.youtube.com'
        }
    }

    useEffect(() => {
        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [])

    const videoReadyHandler = e => {
        e.target.mute()
        e.target.playVideo()
        setPlayer(e.target)
        
        setTimer(setTimeout(() => {
            setVideoLoaded(true)
        }, 150))
    }

    const videoEndHandler = e => {
        e.target.playVideo()
    }

    const videoStateChangeHandler = () => {
        switch (player.getPlayerState()) {
            case -1:
            case 0:
            case 2:
                setVideoPlaying(false)
                break;
            case 1:
                setVideoPlaying(true)
                // if (player.getPlaybackQuality() != 'hd1080') {
                //     player.setPlaybackQuality('hd1080')
                // }
                break;
        }
    }

    return (
        <Wrapper className={className}>
            <VideoBackground>
                <VideoForeground>
                    <YouTube
                        videoId='vNpoBRWraRw'
                        opts={videoOptions}
                        className='video-iframe'
                        onReady={videoReadyHandler}
                        onEnd={videoEndHandler}
                        onStateChange={videoStateChangeHandler}
                    />
                </VideoForeground>
            </VideoBackground>
            <Poster
                videoPlaying={videoPlaying.toString()}
                fluid={data.image.fluid}
                alt={data.image.alt}
            />
        </Wrapper>
    )
}

export default YoutubeVideo