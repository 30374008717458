import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"
import theme from "./../styles/theme"

const StyledPortfolioItem = styled.article`
  text-align: left;

  margin-bottom: ${props => props.theme.mobileVW(50)};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin-bottom: ${props => props.theme.desktopVW(50)};

    &:last-of-type {
      margin-bottom: ${props => props.theme.desktopVW(50)};
    }
  }
`

const Image = styled(Img)`
  margin-bottom: ${props => props.theme.mobileVW(20)};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    margin-bottom: ${props => props.theme.desktopVW(20)};
  }
`

const Title = styled.h5`
  display: block;

  margin-bottom: ${props => props.theme.desktopVW(35)};

  font-family: ${props => props.theme.fontFamily.circularBook};
  font-size: ${props => props.theme.mobileVW(18)};

  @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
    font-size: ${props => props.theme.fontSize.d6};
  }
`

const PortfolioItem = ({ data: { name, slug, thumbnail } }) => {
  const [menuColor, setMenuColor] = useState("#000")

  useEffect(() => {
    setMenuColor(
      getComputedStyle(document.body).getPropertyValue("--menu-color")
    )
  }, [])

  return (
    <StyledPortfolioItem>
      <AniLink to={`/portfolio/${slug}`} cover direction="right" bg={menuColor}>
        <Image fluid={thumbnail.fluid} />
        <Title>{name}</Title>
      </AniLink>
    </StyledPortfolioItem>
  )
}

export default PortfolioItem
