import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.min.css'

import Text from './../components/Text'
import ServiceItem from './../components/ServiceItem'

const StyledServices = styled.div`
    padding: ${props => props.theme.space.paddingMobileLarge} ${props => props.theme.space.paddingMobile};

    text-align: center;

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        padding: 0;
    }
`

const List = styled.ul`
    display: grid;
    grid-template-columns: auto;
    grid-gap: ${props => props.theme.mobileVW(120)};

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        grid-template-columns: auto auto auto;
        grid-gap: ${props => props.theme.desktopVW(60)};
    }
`

const TextWrapper = styled.div`
    width: 100%;
    margin: 0 auto ${props => props.theme.mobileVW(120)} auto;

    h1, h2, h3 {
        margin-bottom: ${props => props.theme.mobileVW(10)};
    }

    p {
        margin-bottom: ${props => props.theme.mobileVW(10)};
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        max-width: ${props => props.theme.desktopVW(600)};
        margin: 0 auto ${props => props.theme.desktopVW(150)} auto;

        h1, h2, h3 {
            margin-bottom: ${props => props.theme.desktopVW(15)};
        }

        p {
            margin-bottom: ${props => props.theme.desktopVW(20)};
        }
    }
`

const Services = ({
    data: {
        title,
        items
    }
}) => {
    return (
        <StyledServices>
            <TextWrapper>
                <ScrollAnimation animateIn='fadeInUp' duration={1.5}>
                    <Text data={title} />
                </ScrollAnimation>
            </TextWrapper>
            <List>
                {items.map((item, index) => (
                    <ScrollAnimation animateIn='fadeInUp' delay={index * 150} duration={1.5} key={index}>
                        <ServiceItem data={item}></ServiceItem>
                    </ScrollAnimation>
                ))}
            </List>
        </StyledServices>
    )
}

export default Services