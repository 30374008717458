import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.min.css'

import Text from './../components/Text'
import ItemsGrid from './../components/ItemsGrid'

const StyledPortfolio = styled.div`
    padding: ${props => props.theme.space.paddingMobileLarge} ${props => props.theme.space.paddingMobile};

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        padding: 0;
    }
`

const Wrapper = styled.div`
    width: 100%;
    max-width: ${props => props.theme.space.maxContentWidthMobile};
    margin: 0 auto;

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        max-width: ${props => props.theme.space.maxContentWidthDesktop};
    }
`

const TextWrapper = styled.div`
    max-width: ${props => props.theme.mobileVW(500)};
    
    margin-bottom: ${props => props.theme.mobileVW(15)};
    
    overflow: hidden;

    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
        max-width: ${props => props.theme.desktopVW(500)};

        margin-bottom: ${props => props.theme.desktopVW(60)};
    }
`

const Portfolio = ({
    data: {
        title,
        items
    }
}) => {
    return (
        <StyledPortfolio>
            <Wrapper>
                <TextWrapper>
                    <ScrollAnimation animateIn='fadeInUp' duration={0.5}>
                        <Text data={title} />
                    </ScrollAnimation>
                </TextWrapper>
            </Wrapper>
            <ItemsGrid data={items} type='portfolio' />
        </StyledPortfolio>
    )
}

export default Portfolio